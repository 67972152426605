var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("會員管理中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("標籤管理")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-2 mb-2 font-weight-bold"},[_vm._v("標籤管理")]),_c('div',{staticClass:"\n          col-12 col-xl-6\n          d-flex\n          flex-column flex-xl-row\n          align-items-start align-items-xl-center\n        "},[(_vm.checkPermission([_vm.consts.TAG_LIST_CREATE]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-3",attrs:{"variant":"primary","to":{ name: 'TagCreate' }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增標籤")]):_vm._e(),_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',{attrs:{"placeholder":"搜尋"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handlePageReset.apply(null, arguments)}},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.handlePageReset}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.keywords,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"variant":"inverse-primary","to":{
                    name: 'TagView',
                    params: { tag_id: data.item.id },
                  }}},[_c('span',{staticClass:"mdi mdi-eye mr-1"}),_vm._v("查看 ")]),_c('b-button',{attrs:{"variant":"inverse-warning","to":{
                    name: 'TagEdit',
                    params: { tag_id: data.item.id },
                  }}},[_c('span',{staticClass:"mdi mdi-lead-pencil mr-1"}),_vm._v("編輯 ")]),_c('b-button',{attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.deleteTags(data.item)}}},[_c('span',{staticClass:"mdi mdi-delete mr-1"}),_vm._v("刪除 ")])]}}])})],1)],1)]),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.totalRows,"perPage":_vm.query.per_page},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }